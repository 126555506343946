import firebase from "../firebase/firebase";
import React, { useEffect, useState } from "react";
import SEO from "../components/seo";
import Moped from "../models/Moped";
import '../scss/404.scss';
import MopedTemplate from "../templates/moped-template";
import getMoped from "../firebase/getMoped";

export interface NotFoundPageProps {
  location: {
    pathname: string
  }
}

const NotFoundPage: React.FC<NotFoundPageProps> = ({ location: { pathname } }) => {
  // const [showMoped, setShowMoped] = useState(false);
  // const [moped, setMoped] = useState<Moped>();

  // useEffect(() => {
  //   let pathData = pathname.split('/').slice(1).map(str => str.trim());
  //   if (pathData[0] === 'mopeds' && pathData[1]) {
  //     setShowMoped(true);
  //     getMoped(pathData[1]).then(moped => {
  //       if (moped) {
  //         setMoped(moped);
  //       } else {
  //         setShowMoped(false);
  //       }
  //     });
  //   }
  // }, [pathname]);

  return (
    // showMoped ? (
    //   moped ? (
    //     <div>
    //       <MopedTemplate pageContext={{
    //         moped,
    //         mopedId: moped.id
    //       }} />
    //     </div>
    //   ) : (
    //     <div></div>
    //   )
    // ) : (
    <div className="page-not-found">
      <SEO title="Page Not Found" />
      <h1>404</h1>
      <h2>Page not found</h2>
      <p>The requested URL was not found on this server</ p>
    </div>
    // )

  );
}

export default NotFoundPage
